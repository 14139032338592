import { Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { InquiryButton } from '../../../../components/buttons/InquiryButton';
import { useBreakpoints } from '../../../../components/hooks/useBreakpoints';
import { GrowInViewport } from '../../../../components/layout/GrowInViewport';

type PpCtaSection = {
  background?: string | null;
  button?: string | null;
  ctaCopy?: string | null;
  ctaTitle?: string | null;
};

const StyledCta = styled('div')<{ backgroundImage: string }>(({ backgroundImage, theme }) => ({
  backgroundImage: `url("${backgroundImage}")`,
  backgroundPosition: 'top',
  backgroundSize: 'cover',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(10),
  padding: theme.spacing(14, 28),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(4),
  },
}));

export function CtaSection({
  background,
  button,
  ctaCopy,
  ctaTitle,
}: PpCtaSection): React.ReactElement {
  const { isSmall } = useBreakpoints();

  return (
    <section>
      <Container disableGutters fixed maxWidth="lg">
        <StyledCta backgroundImage={background || ''}>
          <GrowInViewport timeout={1000}>
            <Stack alignItems="center" spacing={2.5} textAlign="center">
              <Typography color="text.secondary" variant="h5">
                {ctaTitle}
              </Typography>
              <Typography variant={isSmall ? 'bodyHeadingXS' : 'subHeadingS'}>{ctaCopy}</Typography>
              <InquiryButton color="primary" size="small" variant="contained">
                {button}
              </InquiryButton>
            </Stack>
          </GrowInViewport>
        </StyledCta>
      </Container>
    </section>
  );
}
