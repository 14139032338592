import type { ReactElement } from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import type {
  MarkdownRemarkFrontmatterAdvantages,
  MarkdownRemarkFrontmatterCards,
  MarkdownRemarkFrontmatterPartners,
  UsPageQuery,
} from '../../../generated/types';
import { HomepageUsTemplate } from '../../components/templates/HomepageUsTemplate';
import { AdvantagesSection } from '../../modules/homepage/sections/advantages-section/AdvantagesSection';
import { ApiSection } from '../../modules/homepage/sections/api-section/ApiSection';
import { CtaSection } from '../../modules/homepage/sections/cta-section/CtaSection';
import { FeaturesSection } from '../../modules/homepage/sections/features-section/FeaturesSection';
import { HeroSection } from '../../modules/homepage/sections/hero-section/HeroSection';
import { PartnersSection } from '../../modules/homepage/sections/partners-section/PartnersSection';

type PpUsPage = PageProps<UsPageQuery>;

export function UsPage({ data: { standardHomepage, usHomepage } }: PpUsPage): ReactElement {
  return (
    <HomepageUsTemplate seo={usHomepage?.frontmatter?.seo}>
      <HeroSection
        button={usHomepage?.frontmatter?.heroButton}
        image={usHomepage?.frontmatter?.heroImage}
        subtitle={usHomepage?.frontmatter?.heroSubtitle}
        title={usHomepage?.frontmatter?.heroTitle}
      />
      <PartnersSection
        partners={
          standardHomepage?.frontmatter?.partners as Array<MarkdownRemarkFrontmatterPartners>
        }
      />
      <FeaturesSection
        features={usHomepage?.frontmatter?.cards as Array<MarkdownRemarkFrontmatterCards>}
        textWithIcon={usHomepage?.frontmatter?.featuresTextWithIcon}
        title={usHomepage?.frontmatter?.featuresTitle}
      />
      <ApiSection
        image1={usHomepage?.frontmatter?.image1}
        image2={usHomepage?.frontmatter?.image2}
        image3={usHomepage?.frontmatter?.image3}
        image4={usHomepage?.frontmatter?.image4}
        image5={usHomepage?.frontmatter?.image5}
        title={usHomepage?.frontmatter?.apiTitle}
      />
      <AdvantagesSection
        advantages={
          usHomepage?.frontmatter?.advantages as Array<MarkdownRemarkFrontmatterAdvantages>
        }
        copy={usHomepage?.frontmatter?.advantagesCopy}
        textWithIcon={usHomepage?.frontmatter?.featuresTextWithIcon}
        title={usHomepage?.frontmatter?.advantagesTitle}
      />
      <CtaSection
        background={usHomepage?.frontmatter?.ctaBackground}
        button={usHomepage?.frontmatter?.ctaButton}
        ctaCopy={usHomepage?.frontmatter?.ctaSubtitle}
        ctaTitle={usHomepage?.frontmatter?.ctaTitle}
      />
    </HomepageUsTemplate>
  );
}

export const query = graphql`
  query UsPage {
    usHomepage: markdownRemark(frontmatter: { layout: { eq: "UsHomepage" } }) {
      frontmatter {
        seo {
          title
          description
          image
        }
        heroTitle
        heroSubtitle
        heroButton
        heroImage {
          src
          alt
        }
        featuresTitle
        featuresTextWithIcon
        cards {
          id
          title
          subtitle
        }
        apiTitle
        image1 {
          src
          alt
        }
        image2 {
          src
          alt
        }
        image3 {
          src
          alt
        }
        image4 {
          src
          alt
        }
        image5 {
          src
          alt
        }
        advantagesTitle
        advantagesCopy
        advantages {
          id
          title
          subtitle
          image
        }
        ctaTitle
        ctaSubtitle
        ctaBackground
        ctaButton
      }
    }
    standardHomepage: markdownRemark(
      frontmatter: { layout: { eq: "homepage" }, locale: { eq: "en" } }
    ) {
      frontmatter {
        partners {
          id
          logo
          name
        }
      }
    }
  }
`;

export default UsPage;
